import * as React from "react"
import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import Contact from "../components/Contact"
import "../theme/main.less"

const ImpressumPage = () => {
  return (
    <>
      <HelmetSlot
        title="Impressum"
        description="Allgemeine Informationen zum Betrieb und Rechtsform."
      />
      <main>
        <body className="impressum">
          <div className="view">
            <div className="landing">
              <Navigation />
            </div>
            <div className="main">
              <h1>Impressum</h1>
              <div className="content">
                <p className="text">
                  <b style={{ fontSize: "20px" }}>
                    Gebäudetechnik Alexander Gast
                  </b>
                  <br />
                  Inhaber: Alexander Gast
                  <br />
                  Hauptstraße 14
                  <br />
                  53721 Siegburg
                  <br />
                  Telefon:{" "}
                  <a href="tel:+022411696682" alt="Telefonnummer">
                    02241 1696682
                  </a>
                  <br />
                  Email:{" "}
                  <a href="mailto:info@gt-ag.de" alt="Email Adresse">
                    info@gt-ag.de
                  </a>
                  <br />
                  Betriebsnummer: 1204846
                  <br />
                  Steuer-Nummer: 220/5126/2455
                  <br />
                  <br />
                  <br />
                  <b style={{ fontSize: "20px" }}>Öffnungszeiten:</b>
                  <br />
                  Montag 07:30–17:00
                  <br />
                  Dienstag 07:30–17:00
                  <br />
                  Mittwoch 07:30–17:00
                  <br />
                  Donnerstag 07:30–17:00
                  <br />
                  Freitag 07:30–14:00
                  <br />
                  Samstag Geschlossen
                  <br />
                  Sonntag Geschlossen
                  <br />
                  <br />
                  <br />
                  <b style={{ fontSize: "20px" }}>Verantwortlicher Website:</b>
                  <br />
                  Alexander Gast <br />
                  Kontaktmöglichkeiten:
                  <br />
                  Telefon: 02241 1696682
                  <br />
                  Addresse: Hauptstraße 14, 53721 Siegburg
                  <br />
                  E-Mail: info@gt-ag.de
                </p>
                <div className="divider"></div>
                <Contact />
              </div>
              <br></br>
              <div></div>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default ImpressumPage
