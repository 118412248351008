import React from "react"

import phoneIcon from "../static/phone-solid.svg"
import emailIcon from "../static/at-solid.svg"

const Contact = () => {
  return (
    <div className="contact">
      <p>Kontaktieren Sie uns gerne:</p>
      <div className="telephone">
        <div className="icon-wrapper">
          <img src={phoneIcon} width="30" alt="email"></img>
        </div>
        <span>
          <a href="tel:+022411696682">02241 1696682</a>
        </span>
      </div>
      <div className="email">
        <div className="icon-wrapper">
          <img src={emailIcon} width="30" alt="email"></img>
        </div>
        <span>
          <a href="mailto:info@gt-ag.de">info@gt-ag.de</a>
        </span>
      </div>
    </div>
  )
}

export default Contact
